import { FormikActions } from 'formik';
import { GraphQLError } from 'graphql-request/dist/src/types';
import React from 'react';
import { Organization } from '../../types';
import API from '../../utils/api';
import Form, { Field } from '../ui/Form';
import UntitledSubmit from '../ui/UntitledSubmit';

interface LogInFormValues {
  host: string;
}

interface LogInFormProps {
  path: string;
}

// eslint-disable-next-line prefer-destructuring
const SLAB_HOST = process.env.SLAB_HOST;

const LogInForm: React.SFC<LogInFormProps> = ({ path }) => {
  const onSubmit: (
    values: LogInFormValues,
    formik: FormikActions<LogInFormValues>,
  ) => void = ({ host }, { setErrors }) => {
    API.request<{ organization: Organization }>(
      `query organization($host: String!) {
          organization(host: $host) {
            host
          }
        }
      `,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      { host: `${host}.${SLAB_HOST}` },
    )
      .then(({ organization }) => {
        if (organization.host) {
          window.location.assign(`//${organization.host}${path}`);
        }
      })
      .catch(({ response: { errors } }) => {
        if (
          (errors as GraphQLError[]).find(
            ({ message }) => message === 'NOT_FOUND',
          )
        ) {
          setErrors({ host: 'We could not find this team.' });
        }
      });
  };

  return (
    <Form initialValues={{ host: '' }} onSubmit={onSubmit}>
      <Field
        autoFocus
        required
        name="host"
        placeholder="subdomain"
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        append={`.${SLAB_HOST}`}
      />
      <UntitledSubmit variant="primary" block>
        Continue
      </UntitledSubmit>
    </Form>
  );
};

export default LogInForm;
