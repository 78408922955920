import React, { useState } from 'react';
import caret from '../../images/account/caret.svg';
import { LoggedInTeamProps } from '../../utils/withLoggedInTeams';
import UntitledButton from '../ui/UntitledButton';
import styles from './LoggedInTeamCards.module.scss';
import TeamIcon from './TeamIcon';

const MAX_COUNT = 3;

interface LoggedInTeamCardsProps extends LoggedInTeamProps {
  path: string;
}

const LoggedInTeamCards: React.SFC<LoggedInTeamCardsProps> = ({
  loggedInTeams,
  path,
}) => {
  const [isShowingExtra, setIsShowingExtra] = useState(false);

  if (!loggedInTeams.length) {
    return null;
  }

  const extraCount = loggedInTeams.length - MAX_COUNT;

  return (
    <div className={styles.container}>
      {loggedInTeams
        .slice(0, isShowingExtra ? undefined : MAX_COUNT)
        .map(({ id, name, host, logo }) => (
          <a href={`//${host}${path}`} className={styles.card} key={id}>
            <div className={styles.iconContainer}>
              <TeamIcon name={name} logo={logo?.thumb} />
            </div>
            <div>
              <strong>{name}</strong>
              <div className={styles.host}>{host}</div>
            </div>
            <div className={styles.caret}>
              <img src={caret} alt="" role="presentation" />
            </div>
          </a>
        ))}
      {extraCount > 0 && !isShowingExtra && (
        <div className={styles.showMoreContainer}>
          <UntitledButton onClick={() => setIsShowingExtra(true)}>
            Show {loggedInTeams.length - MAX_COUNT} more
          </UntitledButton>
        </div>
      )}
      <div className={styles.orLogin}>
        Or, log in with your team&apos;s hostname:
      </div>
    </div>
  );
};

export default LoggedInTeamCards;
