import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import withLoggedInTeams, {
  LoggedInTeamProps,
} from '../../utils/withLoggedInTeams';
import LoggedInTeamCards from '../account/LoggedInTeamCards';
import Layout from '../layout/Layout';
import Meta from '../layout/Meta';
import UntitledAccountContent from '../layout/UntitledAccountContent';
import UntitledStyle from '../layout/UntitledStyle';
import LogInForm from './LogInForm';
import Link from '../ui/Link';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './LoginPage.module.scss';

type LoginPageProps = LoggedInTeamProps & RouteComponentProps;

const LoginPage: React.SFC<LoginPageProps> = ({ location, loggedInTeams }) => {
  let path = '';
  let redirectTo: string | null = null;

  if (location?.href) {
    const { href } = location;
    const prefix = `${location.origin}/app`;
    if (href.startsWith(prefix)) {
      path = href.replace(prefix, '');

      if (loggedInTeams.length === 1) {
        redirectTo = `//${loggedInTeams[0].host}${path}`;
      }
    }
  }

  useEffect(() => {
    if (redirectTo && window) {
      window.location.replace(redirectTo);
    }
  }, [redirectTo]);

  return redirectTo ? null : (
    <Layout>
      <Meta title="Login - Slab" />
      <UntitledAccountContent
        title="Welcome back!"
        description="Log in to your account"
      >
        <LoggedInTeamCards loggedInTeams={loggedInTeams} path={path} />
        <LogInForm path={path} />
      </UntitledAccountContent>
      <UntitledStyle noBackground>
        <Spacing top={2} bottom={6}>
          <Text center>
            Don&apos;t have an account?{' '}
            <Link
              className={styles.link}
              to="/register/"
              track={{ label: 'login-link' }}
            >
              Sign up for Slab
            </Link>
          </Text>
        </Spacing>
      </UntitledStyle>
    </Layout>
  );
};

export default withLoggedInTeams(LoginPage);
