import React, { useEffect, useState } from 'react';
import API from './api';
import LoggedInTeams from './LoggedInTeams';

export interface LoggedInTeam {
  id: string;
  name: string;
  host: string;
  logo?: {
    original: string;
    thumb: string;
  };
}

export interface LoggedInTeamProps {
  loggedInTeams: LoggedInTeam[];
}

// So we don't lose the button switching between pages
let teamsCache: LoggedInTeam[];

export const useLoggedInTeams = () => {
  const [loggedInTeams, setLoggedInTeams] = useState<LoggedInTeam[] | null>(
    null,
  );

  useEffect(() => {
    const ids = LoggedInTeams.getAll() || [];

    if (!ids.length) {
      setLoggedInTeams([]);

      return;
    }

    if (teamsCache) {
      setLoggedInTeams(teamsCache);
    } else {
      API.request<{ teams: LoggedInTeam[] }>(
        `query organizations($ids: [ID!]!) {
              teams: organizations(ids: $ids) {
                id
                name
                host
                logo {
                  original
                  thumb
                }
              }
            }
          `,
        { ids },
      ).then(({ teams }) => {
        teamsCache = teams;
        setLoggedInTeams(teams);
      });
    }
  }, []);

  return loggedInTeams;
};

const withLoggedInTeams =
  <P extends LoggedInTeamProps>(Component: React.ComponentType<P>) =>
  (ownProps: Omit<P, keyof LoggedInTeamProps>) => {
    const teams = useLoggedInTeams();

    const props = teams ? { ...ownProps, loggedInTeams: teams } : null;

    return props && <Component {...(props as P)} />;
  };

export default withLoggedInTeams;
