import React from 'react';
import styles from './TeamIcon.module.scss';

interface TeamIconProps {
  logo?: string;
  name: string;
}

const TeamIcon: React.SFC<TeamIconProps> = ({ logo, name }) =>
  logo ? (
    <span
      className={styles.teamIcon}
      style={{ backgroundImage: `url(${logo})` }}
    />
  ) : (
    <span className={styles.teamIcon}>{name[0].toUpperCase()}</span>
  );

export default TeamIcon;
